import React, { useEffect } from 'react';
import "../../style.css";

function StatusColumn({ contact, editingValues, setEditingValues, handleUpdateContact, editingContactId }) {

  // Obsługa zmiany statusu
  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    // console.log(`Status selected: ${newStatus}`);

    // Aktualizujemy lokalny stan
    setEditingValues((prev) => {
      const updatedValues = { ...prev, status: newStatus };
    //   console.log('Updated local editingValues: ', updatedValues);
      return updatedValues;
    });
  };

  // Używamy useEffect, aby zaktualizować dane po zmianie statusu
  useEffect(() => {
    // Jeśli status się zmienił, wykonujemy aktualizację kontaktu
    if (editingValues.status !== contact.status) {
    //   console.log('Updated contact after status change: ', { ...contact, status: editingValues.status });
      handleUpdateContact(contact.Id, { ...contact, status: editingValues.status });
    }
  }, [editingValues, contact, handleUpdateContact]);  // useEffect uruchomi się, kiedy zmieni się status

  // Pobieramy aktualny status do przypisania klasy
  const currentStatus = editingContactId === contact.Id ? editingValues.status : contact.status;

  // Logika przypisania klasy na podstawie statusu
  let statusClass = '';
  if (currentStatus === 'zrobione') {
    statusClass = 'status_done';
  } else if (currentStatus === 'realizacja') {
    statusClass = 'status_worked';
  } else if (currentStatus === 'wstrzymane') {
    statusClass = 'status_suspended';
  }

//   console.log('Assigned status class: ', statusClass);

  return (
    <td>
      <div className={`status_bg ${statusClass}`}>
        <select
          value={currentStatus}
          onChange={handleStatusChange}
        >
          <option value="none">none</option>
          <option value="zrobione">Zrobione</option>
          <option value="realizacja">Realizacja</option>
          <option value="wstrzymane">Wstrzymane</option>
        </select>
      </div>
    </td>
  );
}

export default StatusColumn;
