import React, { useState, useEffect } from 'react';

// Funkcja do formatowania daty na wyświetlany format
const formatDisplayDate = (dateString) => {
  if (!dateString) return '';

  const date = new Date(dateString);
  if (isNaN(date)) return dateString; // Jeżeli data jest niepoprawna, zwracamy ją w oryginalnej postaci

  const day = date.getDate();
  const monthNames = ['sty', 'lut', 'mar', 'kwi', 'maj', 'cze', 'lip', 'sie', 'wrz', 'paź', 'lis', 'gru'];
  const month = monthNames[date.getMonth()];

  return `${day} ${month}`;
};

// Funkcja do formatowania daty na format inputa typu date (YYYY-MM-DD)
const formatForInput = (dateString) => {
  if (!dateString) return '';

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

function DateColumn({ contact, editingValues, setEditingValues, handleUpdateContact, editingContactId }) {
  const isEditing = editingContactId === contact.Id;
  const displayDate = isEditing ? editingValues.date : contact.date;

  const [inputType, setInputType] = useState('text'); // Stan do przełączania typu inputu

  // Funkcja obsługi zmiany daty
  const handleChange = (e) => {
    const value = e.target.value;
    if (isNaN(new Date(value))) {
      setEditingValues((prev) => ({ ...prev, date: '' }));
    } else {
      setEditingValues((prev) => ({ ...prev, date: value }));
    }
  };

  // Funkcja obsługi fokusa na inpucie (zmiana typu na 'date')
  const handleFocus = (e) => {
    setInputType('date'); // Ustawiamy typ inputu na 'date' przy focussie
    e.target.value = contact.date  // Obsługuje pustą wartość
  };

  // Funkcja obsługi blur (wyjście z inputu)
  const handleBlur = (e) => {
    setInputType('text'); // Ustawiamy typ inputu z powrotem na 'text'
    const inputValue = e.target.value;
    setEditingValues((prev) => ({ ...prev, date: inputValue }));
    handleUpdateContact(contact.Id);
  };

  // Użycie efektu, aby otworzyć selektor daty po przejściu w tryb edycji
  useEffect(() => {
    if (isEditing) {
      setInputType('date'); // Automatycznie ustawiamy typ na 'date', aby otworzyć selektor
    }
  }, [isEditing]);

  return (
    <td>
      <input
        type={inputType} // Dynamically change type
        className="date-display"
        value={isEditing ? formatForInput(displayDate) : formatDisplayDate(displayDate)}
        onFocus={handleFocus} // Otworzenie wyboru daty
        onBlur={handleBlur}   // Zapisanie i zamknięcie datepickera
        onChange={handleChange}
      />
    </td>
  );
}

export default DateColumn;
