import React from 'react';

function NameColumn({ contact, editingValues, setEditingValues, handleUpdateContact }) {
  return (
    <td>
      <input
        type="text"
        value={editingValues.tasksName}
        onChange={(e) => setEditingValues((prev) => ({ ...prev, tasksName: e.target.value }))}
        onBlur={() => handleUpdateContact(contact.Id)}
      />
    </td>
  );
}

export default NameColumn;
