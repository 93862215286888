import React from 'react';

function OwnerColumn({ contact, users, editingValues, setEditingValues, handleUpdateContact }) {
  return (
    <td>
      <select
        value={editingValues.owner}
        onChange={(e) => setEditingValues((prev) => ({ ...prev, owner: e.target.value }))}
        onBlur={() => handleUpdateContact(contact.Id)}
      >
        <option value="">Brak</option>
        {users.map((user) => (
          <option key={user.login} value={user.login}>
            {user.login}
          </option>
        ))}
      </select>
    </td>
  );
}

export default OwnerColumn;
