import React, { useState } from 'react';
import CheckboxColumn from './columns/CheckboxColumn';
import NameColumn from './columns/NameColumn';
import OwnerColumn from './columns/OwnerColumn';
import StatusColumn from './columns/StatusColumn';
import DescriptionColumn from './columns/DescriptionColumn';
import PhoneColumn from './columns/PhoneColumn';
import DateColumn from './columns/DateColumn';
import { task_endpoint } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

function TaskRow({ contact, users, selectedContacts, setSelectedContacts, setContacts }) {
  const [editingValues, setEditingValues] = useState(contact);
  const [error, setError] = useState(null);

  // Funkcja aktualizująca kontakt
  const handleUpdateContact = async () => {
    try {
      await fetch(task_endpoint, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...editingValues }),
      });
      setContacts((prev) => prev.map((item) => (item.Id === editingValues.Id ? editingValues : item)));
    } catch (error) {
      setError('Błąd aktualizacji kontaktu');
    }
  };

  return (
    <>
      <tr>
        <CheckboxColumn
          contact={contact}
          selectedContacts={selectedContacts}
          setSelectedContacts={setSelectedContacts}
        />
        <NameColumn
          contact={contact}
          editingValues={editingValues}
          setEditingValues={setEditingValues}
          handleUpdateContact={handleUpdateContact}
        />
        <OwnerColumn
          contact={contact}
          users={users}
          editingValues={editingValues}
          setEditingValues={setEditingValues}
          handleUpdateContact={handleUpdateContact}
        />
        <StatusColumn
          contact={contact}
          editingValues={editingValues}
          setEditingValues={setEditingValues}
          handleUpdateContact={handleUpdateContact}
        />
        <DescriptionColumn
          contact={contact}
          editingValues={editingValues}
          setEditingValues={setEditingValues}
          handleUpdateContact={handleUpdateContact}
        />
        <DateColumn
          contact={contact}
          editingValues={editingValues}
          setEditingValues={setEditingValues}
          handleUpdateContact={handleUpdateContact}
        />
        <PhoneColumn
          contact={contact}
          editingValues={editingValues}
          setEditingValues={setEditingValues}
          handleUpdateContact={handleUpdateContact}
        />
      </tr>
      {error && <tr><td colSpan="7" style={{ color: 'red' }}>{error}</td></tr>}
    </>
  );
}

export default TaskRow;
