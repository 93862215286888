import React from 'react';

function DescriptionColumn({ contact, editingValues, setEditingValues, handleUpdateContact }) {
    return (
      <td>
        <input
          type="text"
          value={editingValues.description}
          onChange={(e) => setEditingValues((prev) => ({ ...prev, description: e.target.value }))}
          onBlur={() => handleUpdateContact(contact.Id)}
        />
      </td>
    );
  }

export default DescriptionColumn;
